import Vue from 'vue';
import {
  Button,
  ButtonGroup,
  Form,
  FormItem,
  Input,
  Message,
  Container,
  Header,
  Footer,
  Aside,
  Main,
  Menu,
  Submenu,
  MenuItem,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Row,
  Col,
  Table,
  TableColumn,
  Switch,
  Tooltip,
  Pagination,
  Dialog,
  MessageBox,
  Tag,
  Tree,
  Select,
  Option,
  Cascader,
  Alert,
  Tabs,
  TabPane,
  Steps,
  Step,
  CheckboxGroup,
  Checkbox,
  Upload,
  ColorPicker,
  Image,
  Slider,
  Drawer,
  Radio,
  RadioGroup,
  CascaderPanel,
  Autocomplete,
  Loading,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  PageHeader,
  Carousel,
  CarouselItem,
  Divider,
  Descriptions,
  DescriptionsItem,
  DatePicker,
  Collapse,
  CollapseItem
} from 'element-ui';

Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Container);
Vue.use(Header);
Vue.use(Footer);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Card);
Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Switch);
Vue.use(Tooltip);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Select);
Vue.use(Option);
Vue.use(Cascader);
Vue.use(Alert);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Steps);
Vue.use(Step);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Upload);
Vue.use(ColorPicker);
Vue.use(Image);
Vue.use(Slider);
Vue.use(Drawer);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(CascaderPanel);
Vue.use(Autocomplete);
Vue.use(Loading);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(PageHeader);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Divider);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(DatePicker);
Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
