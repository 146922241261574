<template>
  <el-container class="home-container">
    <el-header class="el-header">
      <div>
        <img src="../assets/LOGO18.png" alt="" style="height: 32px" />
      </div>
      <!--      <el-dropdown trigger="click" @command="handleClick">-->
      <!--        <span class="el-dropdown-link"><i class="el-icon-more"></i> </span>-->
      <!--        <el-dropdown-menu>-->
      <!--          <el-dropdown-item icon="el-icon-switch-button" command="logout">退出</el-dropdown-item>-->
      <!--        </el-dropdown-menu>-->
      <!--      </el-dropdown>-->
    </el-header>
    <el-main>
      <el-divider content-position="center">功能模块</el-divider>
      <el-row>
        <el-row :gutter="8">
          <el-col :span="12">
            <img src="/img/1-单款再创2.webp" class="img-style" alt="单款再创" @click="goto('/innovate')" />
          </el-col>
          <el-col :span="12">
            <img src="/img/8-图文创款.webp" class="img-style" alt="图文创款" @click="goto('/text-creation')" />
          </el-col>
        </el-row>
        <el-row :gutter="8" style="margin-top: 2px">
          <el-col :span="12">
            <img src="/img/9-创意配色.webp" class="img-style" alt="创意配色" @click="goto('/match-color-and-material')" />
          </el-col>
          <el-col :span="12">
            <img src="/img/4-智能抠图2.webp" class="img-style" alt="智能抠图" @click="goto('/cutout')" />
          </el-col>
        </el-row>
        <el-row :gutter="8" style="margin-top: 2px">
          <el-col :span="12">
            <img src="/img/2-款式融合2.webp" class="img-style" alt="款式融合" @click="goto('/fusion')" />
          </el-col>
          <el-col :span="12">
            <img src="/img/3-图转线稿2.webp" class="img-style" alt="图转线稿" @click="goto('/line-drawing')" />
          </el-col>
        </el-row>

        <!--        <el-row :gutter="8">-->
        <!--          <el-col :span="8">-->
        <!--            <img src="/img/1-单款再创2.webp" class="img-style" alt="单款再创" @click="goto('/innovate')" />-->
        <!--          </el-col>-->
        <!--          <el-col :span="8">-->
        <!--            <img src="/img/8-图文创款.webp" class="img-style" alt="图文创款" @click="goto('/text-creation')" />-->
        <!--          </el-col>-->
        <!--          <el-col :span="8">-->
        <!--            <img src="/img/9-创意配色.webp" class="img-style" alt="创意配色" @click="goto('/match-color-and-material')" />-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <!--        <el-row :gutter="8" style="margin-top: 4px">-->
        <!--          <el-col :span="8">-->
        <!--            <img src="/img/4-智能抠图2.webp" class="img-style" alt="智能抠图" @click="goto('/cutout')" />-->
        <!--          </el-col>-->
        <!--          <el-col :span="8">-->
        <!--            <img src="/img/2-款式融合2.webp" class="img-style" alt="款式融合" @click="goto('/fusion')" />-->
        <!--          </el-col>-->
        <!--          <el-col :span="8">-->
        <!--            <img src="/img/3-图转线稿2.webp" class="img-style" alt="图转线稿" @click="goto('/line-drawing')" />-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-row>
      <el-row :gutter="1" style="height: 15px; width: 100%"> </el-row>
      <el-divider content-position="center">作品展示</el-divider>
      <el-row style="margin-top: 10px">
        <el-col :span="24">
          <el-carousel :interval="2000" type="card" height="185px" ref="slideCarousel">
            <el-carousel-item v-for="item in images" :key="item">
              <el-image :src="item" class="carousel-img-style" alt="#" />
            </el-carousel-item>
          </el-carousel>
        </el-col>
      </el-row>
      <el-row style="margin-top: 70px; margin-bottom: 20px">
        <el-col :span="24" style="display: flex; justify-content: center; align-items: center">
          <span><a href="https://beian.miit.gov.cn" target="_blank" style="font-size: smaller; text-decoration: none; color: #4a5569">粤ICP备2024258991号</a></span>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      images: [
        '/img/creations/1.jpg',
        '/img/creations/2.jpg',
        '/img/creations/4.jpg',
        '/img/creations/5.jpg',
        '/img/creations/6.jpg',
        '/img/creations/7.jpg',
        '/img/creations/8.jpg',
        '/img/creations/9.jpg'
      ]
    };
  },
  methods: {
    goto(route) {
      if (!route) {
        return;
      }
      console.log('clicked goto!');
      this.$router.push(route);
    },
    // 滑动切换
    slideBanner() {
      // 选中的轮播图
      const box = document.querySelector('.el-carousel__container');
      let startPoint = 0;
      let stopPoint = 0;
      // 重置坐标
      const resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      // 手指按下
      box.addEventListener('touchstart', function (e) {
        // 手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      // 手指滑动
      box.addEventListener('touchmove', function (e) {
        // 手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      // 当手指抬起的时候，判断图片滚动离左右的距离
      const that = this;
      box.addEventListener('touchend', function (e) {
        // console.log('1：' + startPoint);
        // console.log('2：' + stopPoint);
        if (stopPoint === 0 || startPoint - stopPoint === 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
        }
      });
    }
  },
  mounted() {
    // 调用方法
    this.slideBanner();
  }
};
</script>

<style lang="less" scoped>
.home-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  :deep(.el-divider--horizontal) {
    margin: 30px 0;
  }
}
.el-header {
  background-color: #171631;
  height: 60px;
  color: #f1ecec;
  font-weight: normal;
  font-size: medium;
  vertical-align: middle;
  padding-left: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transform: translate3d(0, 0, 0);
}
.el-main {
  background-color: #171631;
  padding: 60px 10px;
  //height: calc(100% - 120px);
  overflow-y: scroll;
}
.carousel-img-style {
  width: 100%;
  border-radius: 5px;
}

.img-style {
  width: 100%;
  border-radius: 5px;
}
</style>
