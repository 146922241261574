<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区域 -->
      <!--      <div class="avatar_box">-->
      <!--        <img src="../assets/logo.png" alt="" />-->
      <!--      </div>-->
      <!-- 表单区域 -->
      <el-form ref="loginFormRef" label-width="0px" :rules="loginFormRules" class="login_Form" :model="loginForm">
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" prefix-icon="iconfont icon-bussiness-man"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" prefix-icon="iconfont icon-password" type="password"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--    <div class="videoContainer">-->
    <!--      <video style="width: 100%; height: auto" autoplay playsinline muted loop>-->
    <!--        <source src="../assets/video/1394_1713959296.mp4" type="video/mp4" />-->
    <!--      </video>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          {
            min: 3,
            max: 20,
            message: '用户名称长度在 3 到 20 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 30,
            message: '密码长度在 6 到 30 个字符',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post('login', this.loginForm);
        console.log('res:' + JSON.stringify(res));
        if (res.code !== '0') {
          this.$message.error('登录失败');
        } else {
          // 登录成功不提示
          // this.$message({
          //   message: '登录成功',
          //   type: 'success'
          // });
          // 路由跳转
          window.localStorage.setItem('token', res.data.token);
          window.localStorage.setItem('username', res.data.username);
          window.localStorage.setItem('lastLoginTime', res.data.lastLoginTime || ' ');
          window.localStorage.setItem('vipDeadline', res.data.vipDeadline);
          window.localStorage.setItem('adminOrNot', res.data.adminOrNot);

          const targetRouter = window.sessionStorage.getItem('targetRouter') || '/home';
          await this.$router.push(targetRouter);
        }

        // 路由跳转
        // window.sessionStorage.setItem('token', 'res.data.token');
        // this.$router.push('/home');
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login_container {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #171631;
}

.login_box {
  position: absolute;
  width: 320px;
  height: 240px;
  background-color: #fff;
  opacity: 0.85;
  border-radius: 3px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .avatar_box {
    position: absolute;
    width: 65px;
    height: 65px;
    left: 50%;
    box-shadow: 0 0 10px #ddd;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 5px;
    transform: translate(-50%, -50%);
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}

.login_Form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.videoContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  //margin: 0;
  overflow: hidden;
  z-index: -100;
}

.videoContainer:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  background: rgba(25, 29, 34, 0.65);
}
</style>
