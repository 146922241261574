<template>
  <el-container class="home-container">
    <el-header class="el-header">
      <span>我 的</span>
    </el-header>
    <el-main>
      <el-divider content-position="center">账号信息</el-divider>
      <el-row :gutter="1" style="width: 100%; padding: 5px">
        <el-col :span="7">
          <div class="avatar_box">
            <img src="../assets/avatar.svg" alt="" />
          </div>
        </el-col>
        <el-col :span="17" style="margin-top: 10px">
          <span style="display: block; color: aliceblue; font-size: x-large; margin-bottom: 5px"
            >{{ username }}
            <el-button type="warning" size="mini" icon="el-icon-switch-button" style="float: right" @click="logout">登出</el-button>
          </span>
          <span style="display: block; color: #4a5569; font-size: smaller">会员有效期至：{{ vipDeadline }}</span>
          <span style="display: block; color: #4a5569; font-size: smaller">上次登录时间：{{ lastLoginTime }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="1" style="width: 100%; padding: 5px" v-if="adminFlag">
        <el-button type="info" size="small" icon="el-icon-zoom-in" @click="addUserDialogVisible = true">新 增 账 号</el-button>
      </el-row>

      <el-divider content-position="center">创作历史</el-divider>
      <el-row :gutter="8" style="padding-top: 15px">
        <el-col :span="8">
          <el-date-picker size="small" v-model="createdDay" type="date" :editable="false" @change="this.loadHis" placeholder="创作时间"> </el-date-picker>
        </el-col>
        <el-col :span="8">
          <el-button plain size="small" style="background-color: #374f9d; border: 1px solid #374f9d; color: aliceblue" @click="clickOrderByDate"
            ><i class="el-icon-sort el-icon--left"></i>{{ orderByDateBtnText }}</el-button
          >
        </el-col>
        <el-col :span="8">
          <el-select v-model="taskType" @change="changeTaskType" clearable placeholder="创作类别" style="width: 100%">
            <i slot="prefix" class="el-input__icon el-icon-copy-document" style="line-height: 32px"></i>
            <el-option v-for="item in taskOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 15px; padding-top: 15px; display: flex; flex-wrap: wrap">
        <el-col :span="8">
          <el-card :body-style="{ padding: '0px' }" v-for="item in taskHisPar1" :key="item.taskId" style="margin-top: 5px; border: 1px solid #171631">
            <el-image :src="item.thumbnail" @click="showDetail(item.taskId)" style="width: 100%" fit="contain" alt="#">
              <div slot="placeholder" class="image-slot" style="height: 100px; display: flex; justify-content: center; align-items: center">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
            <div style="padding: 3px; font-size: 14px">
              <span>{{ item.taskType }}</span>
              <span style="float: right">{{ item.resultCount }}张</span>
              <div class="bottom clearfix">
                <time class="time">{{ item.createdTime }}</time>
                <div style="float: right; color: #cd3c3c"><i class="el-icon-delete-solid"></i></div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card :body-style="{ padding: '0px' }" v-for="item in taskHisPar2" :key="item.taskId" style="margin-top: 5px; border: 1px solid #171631">
            <el-image :src="item.thumbnail" @click="showDetail(item.taskId)" style="width: 100%" fit="contain" alt="#">
              <div slot="placeholder" class="image-slot" style="height: 100px; display: flex; justify-content: center; align-items: center">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
            <div style="padding: 3px; font-size: 14px">
              <span>{{ item.taskType }}</span>
              <span style="float: right">{{ item.resultCount }}张</span>
              <div class="bottom clearfix">
                <time class="time">{{ item.createdTime }}</time>
                <div style="float: right; color: #cd3c3c"><i class="el-icon-delete-solid"></i></div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card :body-style="{ padding: '0px' }" v-for="item in taskHisPar3" :key="item.taskId" style="margin-top: 5px; border: 1px solid #171631">
            <el-image :src="item.thumbnail" @click="showDetail(item.taskId)" style="width: 100%" fit="contain" alt="#">
              <div slot="placeholder" class="image-slot" style="height: 100px; display: flex; justify-content: center; align-items: center">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
            <div style="padding: 3px; font-size: 14px">
              <span>{{ item.taskType }}</span>
              <span style="float: right">{{ item.resultCount }}张</span>
              <div class="bottom clearfix">
                <time class="time">{{ item.createdTime }}</time>
                <div style="float: right; color: #cd3c3c"><i class="el-icon-delete-solid"></i></div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <div class="addDia">
        <el-dialog title="创作详情" v-loading="loadingDetail" :visible.sync="creationDetailVisible" :fullscreen="true" :center="true" v-if="creationDetailVisible" width="100%">
          <el-row :gutter="1" style="width: 100%; padding: 8px">
            <el-col :span="2"><i class="icon el-icon-document-copy" style="font-weight: bolder"></i></el-col>
            <el-col :span="6" style="font-weight: bolder">创作类别：</el-col>
            <el-col :span="16">{{ selectedTask.taskType }}</el-col>
          </el-row>
          <el-row :gutter="1" style="width: 100%; padding: 8px">
            <el-col :span="2"><i class="icon el-icon-date" style="font-weight: bolder"></i></el-col>
            <el-col :span="6" style="font-weight: bolder">创作时间：</el-col>
            <el-col :span="16">{{ selectedTask.createdTime }}</el-col>
          </el-row>
          <el-row :gutter="1" style="width: 100%; padding: 8px">
            <el-col :span="2"><i class="icon el-icon-picture-outline" style="font-weight: bolder"></i></el-col>
            <el-col :span="8" style="font-weight: bolder">原图/手稿图：</el-col>
            <el-col :span="14"> </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="12" v-for="(item, index) in selectedTask.reqPics" :key="index">
              <div class="custom-image">
                <el-image :src="item" :preview-src-list="[item]" style="width: 100%" fit="scale-down" lazy alt="#">
                  <div slot="placeholder" class="image-slot" style="height: 100px; display: flex; justify-content: center; align-items: center">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="1" style="width: 100%; padding: 8px">
            <el-col :span="2"><i class="icon el-icon-cpu" style="font-weight: bolder"></i></el-col>
            <el-col :span="4" style="font-weight: bolder">参数：</el-col>
            <el-col :span="18">
              <el-collapse>
                <el-collapse-item>
                  <div>{{ selectedTask.params }}</div>
                </el-collapse-item>
              </el-collapse>
            </el-col>
          </el-row>
          <!--          <el-row :gutter="1" style="width: 100%; padding: 8px" v-if="selectedTask.params">-->
          <!--            <el-col :span="24">-->
          <!--              <el-collapse accordion>-->
          <!--                <el-collapse-item>-->
          <!--                  <div>{{ selectedTask.params }}</div>-->
          <!--                </el-collapse-item>-->
          <!--              </el-collapse>-->
          <!--            </el-col>-->
          <!--          </el-row>-->

          <el-row :gutter="1" style="width: 100%; padding: 8px">
            <el-col :span="2"><i class="icon el-icon-finished" style="font-weight: bolder"></i></el-col>
            <el-col :span="7" style="font-weight: bolder">创作结果：</el-col>
            <el-col :span="14"> </el-col>
          </el-row>

          <el-row :gutter="5">
            <el-col :span="12">
              <div v-for="item in resultPics1" :key="item" class="custom-image">
                <el-image :src="item" :preview-src-list="[item]" style="width: 100%" fit="scale-down" alt="#">
                  <div slot="placeholder" class="image-slot" style="height: 100px; display: flex; justify-content: center; align-items: center">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </div>
            </el-col>
            <el-col :span="12">
              <div v-for="item in resultPics2" :key="item" class="custom-image">
                <el-image :src="item" :preview-src-list="[item]" style="width: 100%" fit="scale-down" alt="#">
                  <div slot="placeholder" class="image-slot" style="height: 100px; display: flex; justify-content: center; align-items: center">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </div>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer"> </span>
        </el-dialog>

        <el-dialog title="新增账号" v-loading="loadingAddUser" :visible.sync="addUserDialogVisible" :fullscreen="true" :center="true" v-if="addUserDialogVisible" width="100%">
          <el-form ref="form" :model="newUser" label-width="80px" size="mini">
            <el-form-item label="用户名">
              <el-input v-model="newUser.username"></el-input>
            </el-form-item>
            <el-form-item label="密 码">
              <el-input v-model="newUser.password"></el-input>
            </el-form-item>
            <el-form-item label="会员截止日期">
              <el-col :span="11">
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="newUser.vipDeadlineDate" style="width: 100%"></el-date-picker>
              </el-col>
            </el-form-item>

            <el-button type="primary" size="small" icon="el-icon-zoom-in" @click="addUser">提交</el-button>
          </el-form>

          <span slot="footer" class="dialog-footer"> </span>
        </el-dialog>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  created() {
    this.username = window.localStorage.getItem('username') || ' ';
    this.lastLoginTime = window.localStorage.getItem('lastLoginTime') || ' ';
    this.vipDeadline = window.localStorage.getItem('vipDeadline') || ' ';
    this.adminFlag = window.localStorage.getItem('adminOrNot') === 'true';
    console.log('this.adminFlag type: ', typeof this.adminFlag);
    this.loadHis();
  },
  data() {
    return {
      username: '',
      lastLoginTime: '',
      vipDeadline: '',
      adminFlag: false,
      orderByDateBtnText: '按时间倒序',
      createdDay: new Date(),
      taskType: '',
      taskOptions: [
        { value: '', label: '所有类别' },
        { value: 'INNOVATE', label: '单款再创' },
        { value: 'TEXT_CREATION', label: '图文创款' },
        { value: 'MATCHING_COLOR_MATERIAL', label: '创意配色' },
        { value: 'CUTOUT', label: '智能抠图' },
        { value: 'FUSION', label: '款式融合' },
        { value: 'LINE_DRAWING', label: '图转线稿' }
      ],
      taskHis: [],
      creationDetailVisible: false,
      loadingDetail: true,
      selectedTask: {},
      addUserDialogVisible: false,
      loadingAddUser: false,
      newUser: {
        username: '',
        password: '',
        vipDeadline: '',
        vipDeadlineDate: null
      }
    };
  },
  methods: {
    logout() {
      window.sessionStorage.clear();
      window.localStorage.clear();
      this.$router.push('/home');
    },
    addUser() {
      if (!this.newUser.username || !this.newUser.password || !this.newUser.vipDeadlineDate) {
        this.$message.warning('账号、密码、会员截止日不可为空！');
        return;
      }

      if (this.newUser.username.length < 3 || this.newUser.password.length < 6) {
        this.$message.warning('账号密码太短！');
        return;
      }
      console.log('this.newUser.vipDeadlineDate: ', this.newUser.vipDeadlineDate);
      this.newUser.vipDeadline = this.newUser.vipDeadlineDate.split('T')[0];
      this.addUserDialogVisible = true;
      this.loadingAddUser = true;

      this.$http
        .post('add-user', this.newUser, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          this.loadingAddUser = false;
          const { code, message } = response.data;
          if (code !== '0') {
            this.$message.error(message);
            return;
          }
          this.$message.success('添加成功！');
          Object.keys(this.newUser).forEach((key) => (this.newUser[key] = null));
        })
        .catch((error) => {
          console.log(error.toString());
          this.$message.error('服务异常，请稍后再试！');
          this.loadingAddUser = false;
        });
    },
    clickOrderByDate() {
      if (this.orderByDateBtnText === '按时间倒序') {
        this.orderByDateBtnText = '按时间顺序';
        this.loadHis();
        return;
      }

      if (this.orderByDateBtnText === '按时间顺序') {
        this.orderByDateBtnText = '按时间倒序';
        this.loadHis();
      }
    },
    changeTaskType(taskType) {
      console.log('changeTaskType: ' + taskType);
      this.loadHis();
    },
    showDetail(taskId) {
      this.selectedTask = {};
      this.creationDetailVisible = true;
      this.loadingDetail = true;
      console.log('clicked showDetail...taskId', taskId);
      this.$http
        .get('task-his/detail/' + taskId)
        .then((res) => {
          console.log('res start --- ', JSON.stringify(res.data), ' ---res end', '---');
          const { code, data, message } = res.data;
          if (code !== '0') {
            this.$message.error(message);
            return;
          }
          data.reqPics = data.reqPics.map((p) => this.$fileUrl(p));
          data.resultPics = data.resultPics.map((p) => this.$fileUrl(p));

          this.selectedTask = data;
          this.loadingDetail = false;
        })
        .catch((error) => {
          console.log(error.toString());
          this.$message.error('服务异常，请稍后再试！');
          this.loadingDetail = false;
        });
    },
    loadHis() {
      const params = new URLSearchParams();

      const year = this.createdDay.getFullYear();
      const month = String(this.createdDay.getMonth() + 1).padStart(2, '0');
      const day = String(this.createdDay.getDate()).padStart(2, '0');
      const createdDay = year + '-' + month + '-' + day;

      params.append('createdDay', createdDay);
      const orderByTimeDesc = this.orderByDateBtnText === '按时间倒序';
      params.append('orderByTimeDesc', orderByTimeDesc.toString());
      params.append('taskType', this.taskType);

      // console.log('params: ' + params);

      this.$http
        .get('task-his/list?' + params)
        .then((response) => {
          const { code, data, message } = response.data;
          // console.log('网络请求成功：' + JSON.stringify(respData));
          if (code !== '0') {
            this.$message.error(message);
            return;
          }

          console.log('返回参数：' + JSON.stringify(data));
          const { list } = data;
          if (list != null && list.length > 0) {
            list.forEach((item) => {
              item.thumbnail = this.$fileUrl(item.thumbnail);
            });

            this.taskHis = list;
          } else {
            this.taskHis = [];
          }
          // console.log('taskHis：' + JSON.stringify(this.taskHis));
        })
        .catch((error) => {
          console.log(error.toString());
          this.$message.error('服务异常，请稍后再试！');
        });
    }
  },
  computed: {
    taskHisPar1() {
      return this.taskHis.filter((item, index) => index % 3 === 0);
    },
    taskHisPar2() {
      return this.taskHis.filter((item, index) => index % 3 === 1);
    },
    taskHisPar3() {
      return this.taskHis.filter((item, index) => index % 3 === 2);
    },
    resultPics1() {
      if (this.selectedTask.resultPics) {
        return this.selectedTask.resultPics.filter((item, index) => index % 2 === 0);
      }
      return [];
    },
    resultPics2() {
      if (this.selectedTask.resultPics) {
        return this.selectedTask.resultPics.filter((item, index) => index % 2 === 1);
      }
      return [];
    }
  }
};
</script>

<style scoped lang="less">
.home-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #171631;
}

.home-container {
  :deep(.el-descriptions__body) {
    color: #c4cbd1;
    background-color: #171631;
  }
  :deep(.el-divider__text) {
    position: absolute;
    background-color: #171631;
    padding: 0 5px;
    font-weight: 100;
    color: #c4cbd1;
    font-size: 14px;
  }
  :deep(.el-divider) {
    background-color: #4a5569;
  }
  :deep(.el-divider--horizontal) {
    margin: 24px 10px 0 0;
  }
  :deep(.el-button--small) {
    //padding: 9px 15px;
    width: 100%;
  }
  :deep(.el-date-editor.el-input) {
    width: 100%;
    border-radius: 3px;
  }

  :deep(.el-input__suffix-inner) {
    //right: 1px;
    display: none;
  }
  :deep(.el-input__prefix) {
    left: 1px;
  }

  :deep(.el-input) {
    font-size: 12px;
    font-weight: bolder;
  }

  :deep(.el-input .el-input--suffix) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :deep(.el-input--prefix .el-input__inner) {
    padding-left: 24px;
  }

  :deep(.el-input--suffix .el-input__inner) {
    padding-right: 16px;
  }
  :deep(.el-input__inner) {
    background-color: #374f9d;
    border: 1px solid #374f9d;
    color: aliceblue;
    height: 32px;
    border-radius: 3px;
    line-height: 32px;
    text-align: center;
  }
  :deep(.el-input__icon) {
    text-align: right;
  }
  :deep(.el-collapse-item__header) {
    display: flex;
    align-items: center;
    height: 32px;
    line-height: 48px;
    background-color: #171631;
    color: aliceblue;
    cursor: pointer;
    border-bottom: 1px solid #171631;
    font-size: 13px;
    font-weight: 500;
    transition: border-bottom-color 0.3s;
    outline: 0;
  }
  :deep(.el-collapse) {
    border-top: 1px solid #171631;
    border-bottom: 1px solid #171631;
  }
  :deep(.el-collapse-item__wrap) {
    will-change: height;
    background-color: #171631;
    overflow: hidden;
    box-sizing: border-box;
    border-bottom: 1px solid #171631;
  }
  :deep(.el-collapse-item__content) {
    font-size: 13px;
    color: lightgrey;
  }
}
.addDia {
  :deep(.el-dialog--center .el-dialog__body) {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  :deep(.el-dialog__body) {
    padding: 30px 20px;
    color: #c4cbd1;
    font-size: 14px;
    word-break: break-all;
  }
  :deep(.el-dialog) {
    position: relative;
    //margin: 0 auto 50px;
    background: #171631;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(25, 44, 143, 0.3);
    box-sizing: border-box;
  }
  :deep(.el-dialog__title) {
    line-height: 24px;
    font-size: 18px;
    color: #c4cbd1;
  }
}

.el-header {
  background-color: #171631;
  color: #f1ecec;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-main {
  background-color: #171631;
  padding: 60px 10px;
  //height: calc(100% - 120px);
  overflow-y: scroll;
}

.avatar_box {
  //position: absolute;
  width: 65px;
  height: 65px;
  //left: 90%;
  box-shadow: 0 0 10px #171631;
  border: 1px solid #171631;
  border-radius: 50%;
  padding: 3px;
  transform: translate(20%, 0);
  background-color: #171631;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    //background-color: #eee;
  }
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 3px;
  line-height: 12px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

.custom-image {
  background-color: #131329;
  border-radius: 8px;
  border-color: rgba(9, 14, 46, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 5px;
}
</style>
