<template>
  <el-container class="home-container">
    <router-view></router-view>
    <div class="el-menu-container">
      <el-menu
        border-top-style:
        style="display: flex; justify-content: space-around; border-top: 0.1mm solid #22374d"
        :default-active="$route.path"
        mode="horizontal"
        @select="handleSelect"
        background-color="#171631"
        text-color="grey"
        active-text-color="#85b1ea"
        router
      >
        <el-menu-item index="/home" ref="home">
          <img style="position: absolute; left: 50%; transform: translateX(-50%)" src="../assets/home.svg" alt="" />
          <span slot="title" style="display: block; height: 90%; text-align: center; margin-top: 3px">首页</span>
        </el-menu-item>
        <el-menu-item index="/function">
          <img style="position: absolute; left: 50%; transform: translateX(-50%)" src="../assets/shoes.svg" alt="" />
          <span slot="title" style="display: block; height: 90%; text-align: center; margin-top: 3px">AI设计</span>
        </el-menu-item>
        <el-menu-item index="/mine">
          <img style="position: absolute; left: 50%; transform: translateX(-50%)" src="../assets/user.svg" alt="" />
          <span slot="title" style="display: block; height: 90%; text-align: center; margin-top: 3px">我的</span>
        </el-menu-item>
      </el-menu>
    </div>
  </el-container>
</template>

<script>
export default {
  created() {
    this.activePath = window.sessionStorage.getItem('activePath') || '/home';
  },
  data() {
    return {
      activePath: ''
    };
  },
  methods: {
    handleSelect(key, path) {
      console.log('key:' + key);
      console.log('path:' + path);
      window.sessionStorage.setItem('activePath', path);
      // this.$router.push(path);
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   const menuItemElement = this.$refs.home.$el;
    //   menuItemElement.click();
    // });
  }
};
</script>

<style lang="less" scoped>
.home-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.el-header {
  background-color: #171631;
  height: 60px;
  color: #f1ecec;
  font-weight: normal;
  font-size: medium;
  vertical-align: middle;
  padding-left: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transform: translate3d(0, 0, 0);
}

.el-menu-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 999;
  transform: translate3d(0, 0, 0);
}

.el-menu-container .el-menu--horizontal > .el-menu-item {
  //border-top-color: #542929;
  //height: 60px;
  //line-height: 60px;
  padding-top: 8px;
}
//
//.el-menu-container .el-menu--horizontal > .el-submenu .el-submenu__title {
//  height: 60px;
//  line-height: 60px;
//}

.el-main {
  background-color: #171631;
  padding: 60px 10px;
  //height: calc(100% - 120px);
  overflow-y: scroll;
}
.toggle-button {
  height: 24px;
  color: #fff;
  background-color: #171631;
  line-height: 24px;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
