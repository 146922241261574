import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '../components/Login';
import Index from '../components/Index';
import Home from '@/components/Home';
import Function from '@/components/Functions.vue';
import Mine from '@/components/Mine.vue';
import Innovate from '@/components/design/Innovate';
import Fusion from '@/components/design/Fusion.vue';
import LineDrawing from '@/components/design/LineDrawing.vue';
import Cutout from '@/components/design/Cutout.vue';
import TextCreation from '@/components/design/TextCreation.vue';
import MatchColorAndMaterial from '@/components/design/MatchColorAndMaterial.vue';

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function (location, resolve, reject) {
//   if (resolve || reject) {
//     return originalPush.call(this, location, resolve, reject);
//   }
//   return originalPush.call(this, location).catch((e) => {});
// };

const originPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location, ...rest) {
  [...rest].length ? originPush.call(this, location, ...rest) : originPush.call(this, location, () => {});
};
VueRouter.prototype.replace = function (location, ...rest) {
  [...rest].length ? originReplace.call(this, location, ...rest) : originReplace.call(this, location, () => {});
};

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/home',
      meta: { requiresAuth: false }
    },
    {
      path: '/login',
      component: Login,
      meta: { requiresAuth: false }
    },
    {
      path: '/index',
      component: Index,
      children: [
        { path: '/home', component: Home, meta: { requiresAuth: false } },
        { path: '/function', component: Function, meta: { requiresAuth: true } },
        { path: '/mine', component: Mine, meta: { requiresAuth: true } }
      ]
    },
    {
      path: '/innovate',
      component: Innovate,
      meta: { requiresAuth: true }
    },
    {
      path: '/fusion',
      component: Fusion,
      meta: { requiresAuth: true }
    },
    {
      path: '/line-drawing',
      component: LineDrawing,
      meta: { requiresAuth: true }
    },
    {
      path: '/cutout',
      component: Cutout,
      meta: { requiresAuth: true }
    },
    {
      path: '/text-creation',
      component: TextCreation,
      meta: { requiresAuth: true }
    },
    {
      path: '/match-color-and-material',
      component: MatchColorAndMaterial,
      meta: { requiresAuth: true }
    }
  ]
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('token');
  const isLoggedIn = token != null; // TODO 验证token有效性
  const requiresAuth = to.meta.requiresAuth;
  const inLoginPage = to.path === '/login';

  if (requiresAuth && !isLoggedIn) {
    if (inLoginPage) {
      next(); // 已经在登录页面，继续路由导航
    } else {
      window.sessionStorage.setItem('targetRouter', to.path);
      next('/login'); // 需要登录且未登录，重定向到登录页面
    }
  } else {
    next(); // 否则，继续路由导航
  }
});

export default router;
