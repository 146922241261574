<template>
  <el-container class="home-container">
    <el-header class="el-header">
      <span>AI设计</span>
    </el-header>
    <el-main>
      <el-row :gutter="8">
        <el-col :span="12">
          <img src="/img/1-单款再创2.webp" class="img-style" alt="单款再创" @click="goto('/innovate')" />
        </el-col>
        <el-col :span="12">
          <img src="/img/8-图文创款.webp" class="img-style" alt="图文创款" @click="goto('/text-creation')" />
        </el-col>
      </el-row>
      <el-row :gutter="8" style="margin-top: 2px">
        <el-col :span="12">
          <img src="/img/9-创意配色.webp" class="img-style" alt="创意配色" @click="goto('/match-color-and-material')" />
        </el-col>
        <el-col :span="12">
          <img src="/img/4-智能抠图2.webp" class="img-style" alt="智能抠图" @click="goto('/cutout')" />
        </el-col>
      </el-row>
      <el-row :gutter="8" style="margin-top: 2px">
        <el-col :span="12">
          <img src="/img/2-款式融合2.webp" class="img-style" alt="款式融合" @click="goto('/fusion')" />
        </el-col>
        <el-col :span="12">
          <img src="/img/3-图转线稿2.webp" class="img-style" alt="图转线稿" @click="goto('/line-drawing')" />
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  methods: {
    goto(route) {
      if (!route) {
        return;
      }
      console.log('clicked goto!');
      this.$router.push(route);
    }
  }
};
</script>

<style scoped lang="less">
.home-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #171631;
}

.el-header {
  background-color: #171631;
  color: #f1ecec;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-main {
  background-color: #171631;
  padding: 60px 10px;
  //height: calc(100% - 120px);
  overflow-y: scroll;
}

.img-style {
  width: 100%;
  border-radius: 5px;
}
</style>
